<template>
  <div class="no-legalize-box">
    <van-nav-bar
      title="关联公司"
      class="header"
    />
    <div class="no-legalize-con">
      <div class="no-legalize">
        <img src="../../assets/positionMgt/no_legalize.png" alt />
        <p>您还未绑定公司，需要绑定公司和认证后才能正常发布职位哦～</p>
        <div class="go-legalize-box">
          <van-button type="info" round block @click="goLegalize">立即绑定</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'noLegalize',
    data() {
      return {};
    },
    methods: {
      goLegalize() {
        this.$router.push({ path: '/companyBinding', query: { type: 'add' } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .no-legalize-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .header {
      height: 44px;
    }
    .no-legalize-con {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      .no-legalize {
        width: 276px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          height: 170px;
          width: 170px;
        }
        p {
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          text-align: center;
        }
        .go-legalize-box {
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          padding: 0 46px;
        }
      }
    }
  }
</style>
